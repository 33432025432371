import React from 'react';

const usePathName = () => {
  const [pathName, setPathName] = React.useState<string>(window.location.pathname);

  React.useEffect(() => {
    const setLocation = () => setPathName(window.location.pathname);
    window.addEventListener('popstate', setLocation);
    return () => {
      window.removeEventListener('popstate', setLocation);
    };
  }, []);

  return { pathName, setPathName };
};

export default usePathName;

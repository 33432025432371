import styled from 'styled-components';

export default styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
  .conviva-loading-wrapper {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    &.loading {
      display: block;
    }
  }
  /* &.sub-menu {
    padding-left: 265px;
    box-sizing: border-box;
    .conviva-loading-wrapper {
      margin-left: 135px;
    }
  } */
  > iframe {
    width: 100%;
    height: 100%;
    border: 0;
    overflow: auto;
    &.conviva-iframe-loading {
      opacity: 0;
    }
  }
`;


import React from 'react';

function useOnClickOutside(ref, handler, exceptions: string[] = []) {
  React.useEffect(
    () => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target) || exceptions.some(id => document.querySelector(id)?.contains(event.target))) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler],
  );
};
export default useOnClickOutside;